<template>
  <div class="container">
    <div class="product__breadcrumbs">
      <Breadcrumbs :breadcrumbs="breadcrumbs" />
    </div>
    <div class="collection__product__top mobile-container">
      <div class="left">
        <div class="collection__product-title">{{ product?.name }}</div>
        <WishBtn :product-id="Number(product?.id)" />
      </div>
      <div
        v-if="promotionLabel"
        class="right"
      >
        <div
          v-html="promotionLabel"
          class="collection__promotion-label"
        />
      </div>
    </div>

    <div v-if="Boolean(product && Object.keys(product).length > 0)">
      <CollectionSliderImages
        class="tm-collection__main-slider"
        :is-collection="true"
        :images="productImages"
        :products-dots="productsDots"
      />

      <div
        v-html="promotionLabel"
        class="collection__promotion-label --mobile"
      />

      <div class="tm-collection__product-subtitle mobile-container">Товары коллекции</div>

      <CategoriesProductsCollection
        class="tm-collection__tabs"
        :tabs="sectionProductsCollection"
        :active-tab="activeCategoryProductsCollection"
        @onTabCategory="scrollProductsSection"
      />

      <DescriptionCard
        v-if="product"
        :product="product"
        :products-has-price="productsHasPrice"

        @onScrollProduct="scrollToProduct"
        @onScrollProducts="scrollToProductsHead"
      />

      <template
        v-for="(section, index) in sectionProductsCollection"
        :key="`sectionProductsCollection-${ index }`"
      >
        <ProductsSection
          v-if="section.products.length > 0"
          :id="section.id"
          :category-id="section.id"
          :title="section.label"
          :products="section.products"
          @changeActiveCategory="changeActiveCategory"
        />
      </template>
    </div>
    <div v-else>
      <LoadingContent
        :collection="true"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ICategoryProductsCollection, ISectionProducts } from '~/models/PropductsSet';
import LoadingContent from '~/components/products/LoadingContent.vue';
import { useRoute, useNuxtApp } from '#app';
import type { IProduct } from '#sitis/internal/controllers/products/models/Product';
import { Product } from '#sitis/internal/controllers/products/models/Product';

import CollectionSliderImages from '~/components/collection/CollectionSliderImages.vue';
import useProduct from '#sitis/composables/useSitisProduct';
import { productStore as useProductStore } from '#sitis/stores/modules/product-store';
import CategoriesProductsCollection from '~/components/collection/CategoriesProductsCollection.vue';
import DescriptionCard from '~/components/collection/description-card/DescriptionCard.vue';
import WishBtn from '~/components/UI/WishBtn.vue';
import getDiscountLabel from "~/utils/getDiscountLabel";
import type {ComputedRef} from "vue";

const ProductsSection = defineAsyncComponent(() => import('~/components/collection/ProductsSection.vue'));

interface IProductImageDot {
			id:number;
			left:number;
			product_id:number;
			top:number;
}
export interface IProductWithDots {
	product: IProduct | null;
	top: number;
	left: number;
	product_id: number;
	id: number
}
const Breadcrumbs = defineAsyncComponent(() => import('~/components/UI/Breadcrumbs.vue'));
const $route = useRoute();
const { $api } = useNuxtApp();
const productsCollection = ref<IProduct[]>([]);
const categoryProductsCollection = ref<ICategoryProductsCollection[]>();
const productsHasPrice = ref<IProduct[]>([]);
const sectionProductsCollection = ref<ISectionProducts[]>([]);
const activeCategoryProductsCollection = ref<number | undefined>();
const isLoadProductsHasPrice = ref(true);
const { getProduct } = useProduct({ extensions: ['short_characteristics'] });

const { data: product } = await useAsyncData('getCurrentProducts', () => getProduct());

if (!product.value) {
  throw createError({ message: 'Page not found', statusCode: 404, fatal: true });
}
useSitisCMSPage().setSEOTitles({
  seoTitle: product.value?.seo?.title,
  seoDescription: product.value?.seo?.description,
  seoKeywords: product.value?.seo?.keywords
});
const breadcrumbs = computed(() => useProductStore().breadcrumbs);
const productsDots = computed(():IProductWithDots[] => {
  const productSetImageDots:IProductImageDot[] = product?.value?.productSetImageDots || [];

  return productSetImageDots.map((item) => ({
    ...item,
    product: productsCollection.value.find((t) => +t.id === +item.product_id) || null
  })).filter((t: any) => Boolean(t?.product));
});

const productImages = computed(() => product?.value?.imageIds || []);

const scrollToProductsHead = () => {
  const scrollElement:HTMLElement | null = document.getElementById('collection-products-head');
  if (scrollElement && 'scrollIntoView' in scrollElement) {
    window.scrollTo({
      top: scrollElement.offsetTop - 185,
      behavior: 'smooth'
    });
  }
};

//   // Получение дополнительный данных по коллекции
const getCategoryProductsCollection = async () => {
  const pageSlug = $route.params.slug;

  const data:ICategoryProductsCollection[] = await $api.agent
    .get(`products/${pageSlug}/product-set-children-characteristic-groups?expand=image`)
    .then((res) => res._data)
    .catch(() => []);

  const catProductsCollection = data?.map((category:ICategoryProductsCollection) => ({
    id: category?.id,
    label: category?.name || '',
    image: category?.image || ''
  }));

  categoryProductsCollection.value = catProductsCollection;

  activeCategoryProductsCollection.value =
     categoryProductsCollection?.value?.[0]?.id;

  await getProductsCollection(catProductsCollection);
};

const getProductsCollection = async (categories:ICategoryProductsCollection[]) => {
  const productsExpand = [
    'characteristics',
    'options.variants',
    'options',
    'options.variants.image',
    'options.variants.exclusions',
    'optionsImages',
    'optionsImages.image',
    'promotions',
    'short_characteristics'
  ].join(',');

  const pageSlug = $route.params.slug;

  let products:IProduct[] = await $api.agent
    .get(`products/${pageSlug}/product-set-children?expand=${productsExpand}&per-page=999`)
    .then((res) => res._data)
    .catch(() => []);

  products = products.map((product:IProduct) => new Product(product).toJSON());
  const sectionProducts:ISectionProducts[] = [];

  categories.forEach((category) => {
    sectionProducts.push({
      ...category,
      products: []
    });
  });

  products.forEach((product) => {
    sectionProducts.find((t) => t.id === product.characteristicGroupId)?.products.push(product);
  });

  productsCollection.value = products;
  sectionProductsCollection.value = sectionProducts;
};
const getProductsHasPrice = async () => {
  const pageSlug = $route.params.slug;

  let products:IProduct [] = await $api.agent
    .get(`products/${pageSlug}/product-set-children?has_price=1&expand=characteristicGroup.image`)
    .then((res) => res._data)
    .catch(() => []);

  products = products.map((product) => new Product(product).toJSON());

  productsHasPrice.value = products;
  isLoadProductsHasPrice.value = false;
};
const changeActiveCategory = (categoryId:number) => {
  activeCategoryProductsCollection.value = categoryId;
};
const scrollProductsSection = (categoryId:number) => {
  activeCategoryProductsCollection.value = categoryId;

  const offsetTop =
      document.getElementById(`category-${categoryId}`)?.offsetTop || 0;
  if (offsetTop <= 100) {
    return;
  }

  const paddingTop = 174;

  window.scrollTo({
    top: offsetTop - paddingTop,
    behavior: 'smooth'
  });
};

const scrollToProduct = (productId:number) => {
  const collectionScrollProduct = productId;
  if (!collectionScrollProduct) {
    return;
  }

  const productOffsetTop:HTMLDivElement | null = document.querySelector(
      `[data-product-id="${collectionScrollProduct}"]`
  );
  if (!productOffsetTop) {
    return;
  }

  const offsetTop = productOffsetTop?.offsetTop || 0;
  const paddtingTop = 174;
  window.scrollTo({
    top: offsetTop - paddtingTop,
    behavior: 'smooth'
  });
};

const promotionLabel: ComputedRef<string> = computed(() => {
  const _item = getDiscountLabel(product.value);
  return _item?.label || ""
})

onMounted(async () => {
  await getCategoryProductsCollection();
  await getProductsHasPrice();
  window.addEventListener('scroll', () => {
    const scrollDistance = window.scrollY;
    sectionProductsCollection.value.forEach((item: any) => {
      const element: any = document.getElementById(item.id);
      if (element?.offsetTop <= scrollDistance+200) {
        activeCategoryProductsCollection.value = item.id;
      }
    });
  });
});
onUnmounted( () => {
  window.removeEventListener('scroll', () => null);
})
</script>

<style lang="scss">

.tm-collection__main-slider{
  margin-bottom: 30px;
}

.collection__product__top{
  margin-bottom: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > .left{
    display: flex;
    align-items: center;
    gap: 15px;
  }
}

.collection__product-title{
  font-weight: 700;
  font-size: 62px;
  line-height: 55px;
  color: #000;
}

.tm-collection__product-subtitle{
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  margin-bottom: 8px;
}

.tm-collection__tabs {
  margin-bottom: 30px;
}

.mobile-button-scroll-products {
  z-index: 201;
  position: fixed;
  bottom: 15px;
  left: 15px;
  width: calc(100% - 30px);
}

.collection__promotion-label {
  font-size: 24px;
  line-height: 31px;
  color: #fff;
  background: red;
  padding: 8px 16px;

  &.--mobile {
    display: none;
  }
}

@media (max-width: 1023px) {
  .tm-collection__main-slider {
    margin: 0 -11px;
    margin-bottom: 12px;

    & .product-slider-images__slider{
      border-radius: initial;
    }
  }

  .collection__information {
    margin: 0 -11px;
  }
  .collection__product__top {
    padding: 0;
  }

  .collection__product__top{
    margin-bottom: 22px;
    .right {
      display: none;
    }
  }

  .collection__product-title{
    font-size: 24px;
    line-height: 31px;
  }

  .collection__promotion-label {
    display: inline-block;
    padding: 2px 5px;
    margin-bottom: 12px;
    color: #fff;
    font-size: 18px;
    line-height: 23px;

    &.--mobile {
      display: inline-flex;
    }
  }

  .collection__collection-information-top {
    margin-bottom: 10px;
  }
  .collection__collection-information-body {
    margin-bottom: 16px;
  }
  .collection__collection-information-price-current {
    font-size: 18px;
    line-height: 23px;
  }
  .collection__collection-information-price-old {
    font-size: 12px;
    line-height: 16px;
  }

  .product__breadcrumbs {
    .breadcrumbs-custom {
      margin-bottom: 0;
    }
    .breadcrumbs-custom__items {
      padding: 0;
    }
  }
}

@media (max-width: 990px) {
  .tm-collection__product-subtitle {
    padding: 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 12px;
  }
  .tm-collection__tabs {
    padding: 0;
  }
}
</style>
