<template>
  <div class="categories mobile-container">
    <template
      v-for="(category, index) in tabs"
      :key="`ControlsProductsCollection-${ index }`"
    >
      <div
        v-if="category.products.length > 0"
        :class="{
          'active': Boolean(activeTab === category.id)
        }"
        @click="() => onTabCategory(category.id)"
      >
        {{ category.label }}
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">

import type { ISectionProducts } from '~/models/PropductsSet';

const { tabs, activeTab } = defineProps<{
	tabs:ISectionProducts[],
	activeTab:number | undefined;
}>();
const emit = defineEmits<{
	onTabCategory:[categoryId: number],
}>();

const onTabCategory = (categoryId:number) => {
  emit('onTabCategory', categoryId);
};
</script>

<style scoped lang="scss">
.categories {
  display: flex;
  flex-wrap: wrap;
  position: sticky;
  top: 130px;
  z-index: 201;
  background: white;

}

.categories > div {
  margin-left: 30px;
  padding: 12px 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  cursor: pointer;
  transition: all 0.2s;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    color: var(--primary);
  }

  &.active {
    color: var(--primary);
  }
}

@media (max-width: 1023px) {
  .categories{
    position: initial;
  }
}

@media(max-width: 659px){
  .categories {
    gap: 0 12px;

    & > div {
      font-size: 16px;
      line-height: 22px;
      padding: 6px 0;
      margin-left: 0;
    }
  }
}
</style>
