<template>
  <div
    v-if="!isDisabled"
    v-show="isInitPosition"
    ref="dotCard"
    class="product-dot"
    :style="{ top: `${position.y}%`, left: `${position.x}%` }"
    :class="{'--bottom': position.bottom}"
    @click="scrollToProduct"
  >
    <div
      class="product-dot-pin"
      @mouseover="getProduct"
    />
    <div
      class="product-dot-card"
      :class="{'--loading': !product}"
    >
      <template v-if="product">
        <div class="product-dot-card__name h5 semibold">
          {{ product.name }}
        </div>
        <div class="product-dot-card__image">
          <nuxt-img
            :src="String(product.image_id)"
            width="300"
            height="300"
            fit="fill"
            loading="lazy"
          />
        </div>
        <div
          class="product-dot-card__prices"
          :class="{'--discount': isVisibleOldPrice}"
        >
          <div class="--current">{{ $priceFormat({ amount: productPrice, decimalCount: 0 }) }} ₽</div>
          <div v-if="isVisibleOldPrice" class="--old">{{ $priceFormat({ amount: productPriceOld, decimalCount: 0 }) }} ₽</div>
        </div>
      </template>
      <template v-if="!product">
        <img src="~/assets/img/svg/loading/loading-primary.svg"/>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed, type ComputedRef, onMounted} from 'vue';
import collectionImageSizeAdmin from "~/constants/collection-image-size-admin.json";
import {scrollToElement} from "~/utils/scrollToElement";

const props = defineProps({
  info: {
    type: Object as any,
    default: () => {
    }
  }
});
const {
  $api,
  $numberFormat,
  $priceFormat
} = useNuxtApp();

const dotCard: any = ref(null);
const product: any = ref(null);
const isLoadProduct = ref(true);
const isDisabled: Ref<boolean> = ref(false);
const isInitPosition: Ref<boolean> = ref(false);

const isVisibleOldPrice = computed(() => {
  return Boolean(product.value?.price_old && product.value?.price_old > product.value?.price)
})

const getProduct = async () => {
  if (!isLoadProduct.value || product.value?.id) {
    return
  }
  const fields = ['id', 'name', 'price', 'price_old', 'image_id', 'status'];
  const _product = await $api.agent.get(`/products/${props.info.product_id}?fields=${fields.join(",")}`).then((res: any) => {
    return res?._data
  }).catch(() => {
    return null
  });
  if (!_product) {
    isDisabled.value = true;
    position.value = {x: 0, y: 0, bottom: false};
    return
  }

  product.value = _product;
  isLoadProduct.value = false;
};

const position = ref({x: 0, y: 0, bottom: false});
const setPosition = () => {
  const isPercentVersion = true;

  const parent = dotCard.value.parentElement;
  const parentSize = parent.getBoundingClientRect();

  const differenceWidth = (100 * (parentSize.width - collectionImageSizeAdmin.width) / collectionImageSizeAdmin.width);
  const differenceHeight = (100 * (parentSize.height - collectionImageSizeAdmin.height) / collectionImageSizeAdmin.height);

  const positionTopPx = props?.info?.top + ((props?.info?.top / 100) * differenceHeight);
  const positionLeftPx = props?.info?.left + ((props?.info?.left / 100) * differenceWidth);

  const positionTopPercent = Boolean(isPercentVersion) ? props?.info?.top : (positionTopPx / parentSize.height) * 100;
  const positionLeftPercent = Boolean(isPercentVersion) ? props?.info?.left : (positionLeftPx / parentSize.width) * 100;

  let isBottomPosition = false;
  if ((positionTopPx - 160) <= 0) {
    isBottomPosition = true;
  }

  position.value = {x: positionLeftPercent, y: positionTopPercent, bottom: isBottomPosition};
  isInitPosition.value = true;
};
onMounted(() => {
  setPosition();
});

const scrollToProduct = () => {
  scrollToElement(`[data-product-id="${props.info.product_id}"]`);
}

const productPrice: ComputedRef<number | undefined> = computed(() => {
  let _optionsPrice = Object.keys(props?.info?.option || {}).map((optionKey: string) => {
    const optionValue = props?.info?.option?.[optionKey];
    const optionVariant = ((product?.value?.options || []).find((t: any) => String(t?.id) === String(optionKey))?.variants || []).find((t: any) => String(t?.id) === String(optionValue));
    return optionVariant?.price || 0
  });
  _optionsPrice = _optionsPrice.reduce((val: number, item: number) => {
    return val + item
  }, 0)
  return _optionsPrice + product.value?.price || 0
})
const productPriceOld: ComputedRef<number | undefined> = computed(() => {
  if (!isVisibleOldPrice.value) {
    return null
  }

  let _optionsPrice = Object.keys(props?.info?.option || {}).map((optionKey: string) => {
    const optionValue = props?.info?.option?.[optionKey];
    const optionVariant = ((product?.value?.options || []).find((t: any) => String(t?.id) === String(optionKey))?.variants || []).find((t: any) => String(t?.id) === String(optionValue));
    return optionVariant?.price_old || optionVariant?.price || 0
  });
  _optionsPrice = _optionsPrice.reduce((val: number, item: number) => {
    return val + item
  }, 0)
  return _optionsPrice + product.value?.price_old || 0
})
</script>

<style lang="scss" scoped>
.product-dot {
  position: absolute;

  &.--bottom {
    .product-dot-card {
      top: 20px;
      bottom: initial;
      border-radius: 0 10px 10px 10px;
      &:hover {
        cursor: pointer;
      }

      &:after {
        top: initial;
        bottom: 100%;
      }

      &:before {
        top: -6px;
        transform: scaleX(1) scaleY(-1);
      }
    }
  }

  &:hover .product-dot-pin {
    background: white;
    border-color: red;
  }

  &.--load-buy .product-dot-card:after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    background-image: url("@/assets/img/svg/loading/loading-primary.svg");
    background-size: 24px;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
  }

  &:hover .product-dot-card {
    display: block;
  }
}

.product-dot-pin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;

  background: red;
  border: 6px solid #fff;
  box-shadow: 0 3px 5px 0 #00000070;
  border-radius: 100%;
  width: 36px;
  height: 36px;
  cursor: pointer;
  transition: all 1s;
  display: block;
  box-sizing: border-box;
}

.product-dot-card {
  display: none;
  position: absolute;
  left: 5px;
  bottom: 10px;
  width: 100vw;
  max-width: 300px;
  z-index: 91;
  padding: 16px;
  box-sizing: border-box;
  background-color: #FFF;
  border-radius: 10px 10px 10px 0;
  box-shadow: 4px 6px 31px 0px #0000000D;

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: -20px;
    right: 0;
    height: 20px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    background-size: contain;
    background-repeat: no-repeat;
    width: 14px;
    height: 6px;
    filter: invert(1);
  }

  &.--loading {
    height: 64px;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 48px;
      height: 48px;
      object-fit: contain;
    }
  }
}

.product-dot-card__name {
  margin-bottom: 16px;
}

.product-dot-card__image {
  display: flex;
  margin-bottom: 16px;
  position: relative;

  img {
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
  &:after {
    content: "";
    float: left;
    padding-top: 65%;
  }
}

.product-dot-card__prices {
  display: flex;
  align-items: flex-end;
  margin-bottom: 24px;

  .--current {
    font-size: 16px;
    font-weight: 600;
    line-height: 120%;
  }

  .--old {
    margin-left: 8px;
    color: #808080;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 12px;
    line-height: 20px;
    text-decoration: line-through;
  }

  &.--discount .--current {
    color: red;
  }
}
</style>
