<template>
  <div class="collection__information">
    <div class="collection__information-left">
      <div class="tabs__header">
        <template
          v-for="(item,idx) in tabs"
          :key="`tab-${idx}`"
        >
          <div
            :class="{'__active': currentTab.key === item.key}"
            @click="setCurrentTab(item)"
          >
            <div class="lg-roofs__img-title">{{ item.label }}</div>
          </div>
        </template>
      </div>
      <div class="tabs__body">
        <div v-if="currentTab.key === 'description' ">
          <div v-html="cilekAkciiAdd"/>
          <Description
            :description="product.description || ''"
            :short-description="product.shortDescription"
            :characteristics-description="characteristicsDescription"
          />
        </div>

        <div v-if="currentTab.key === 'delivery' && deliveryDateDays">
          <div class="mt-8">
            <Delivery
              :delivery-date-days="deliveryDateDays"
            />
          </div>
        </div>

        <div v-if="currentTab.key === 'payment' ">
          <PaymentInfo
            :delivery-date-days="paymentInfo"
          />
        </div>
      </div>
    </div>
    <div class="collection__information-right">
      <InformationCard
        :product="product"
        :products-has-price="productsHasPrice"

        @onScrollProduct="onScrollToProduct"
        @onScrollProducts="onScrollToProducts"
      />
      <div class="information-card__delivery-root">
        <div class="information-card__delivery-days">{{ deliveryDateDays }}</div>
        <div class="information-card__delivery-btns">
          <span @click="openFastOrder">Быстрый заказ</span>
          <span class="tooltip">
            <span class="tooltip__item">Как узнать точный срок доставки?</span>
            <span
              v-html="howCanDeliveryTime"
              class="tooltip__hover"
            />
          </span>
        </div>
      </div>
    </div>
    <FastOrderModal
      v-if="isFastOrderOpen"
      @closePopup="() => isFastOrderOpen=false"
    />
  </div>
</template>

<script setup lang="ts">
import type { IProduct } from '#sitis/internal/controllers/products/models/Product';
import Description from '~/components/collection/description-card/Description.vue';
import Delivery from '~/components/collection/description-card/Delivery.vue';
import PaymentInfo from '~/components/collection/description-card/PaymentInfo.vue';

import InformationCard from '~/components/collection/description-card/InformationCard.vue';
import {appConfigStore} from "#sitis/stores/modules/app-store";
import type {ComputedRef} from "vue";

const FastOrderModal = defineAsyncComponent(() => import('~/components/collection/FastOrderModal.vue'));
interface ITab {
	key?: string;
	label?: string;
}

const { product, productsHasPrice } = defineProps<{
	product:IProduct;
	productsHasPrice:IProduct[];
}>();
const emit = defineEmits<{
	onScrollProduct:[id:number],
	onScrollProducts:[]
}>();
const currentTab = ref<ITab>({ key: 'description', label: 'Описание' });
const tabs:ITab[] = [
  { key: 'description', label: 'Описание' },
  { key: 'delivery', label: 'Доставка и сборка' },
  { key: 'payment', label: 'Оплата' }
];

const configStore = appConfigStore();

const setCurrentTab = (item:ITab) => {
  currentTab.value = item;
};
const isFastOrderOpen = ref<boolean>(false);
const characteristicsDescription = computed(() => (product.characteristics || [])
  .find((t) => t.name === 'Описание коллекции')?.characteristics || []);
const deliveryDateDays = computed(() => {
  return configStore?.$state?.vars?.['Доставка и сборка'] || '';
});
const paymentInfo = computed(() => {
  return configStore?.$state?.vars?.['Оплата'] || '';
});
const howCanDeliveryTime = computed(() => {
  return configStore?.$state?.vars?.['Как узнать точный срок доставки?'] || '';
});

const onScrollToProduct = (productId:number) => {
  emit('onScrollProduct', productId);
};
const onScrollToProducts = () => {
  emit('onScrollProducts');
};
const openFastOrder = () => {
  isFastOrderOpen.value = true;
};

const cilekAkciiAdd: ComputedRef<string> = computed(() => {
  return configStore?.$state?.vars?.cilek_akcii_add || ""
});
</script>

<style lang="scss">
.collection__information {
  background: #f0f0f0;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  padding: 21px 34px;
}

.collection__information-left {
  width: calc(50% - 10px);
}

.collection__information-right {
  width: 50%;
  padding: 0 0 0 13px;
}

.tabs__header {
  display: flex;
  position: relative;

  & > * {
    display: flex;
    position: relative;
    margin-right: 25px;
    padding-bottom: 14px;
    cursor: pointer;

    font-size: 24px;
    line-height: 53px;
    color: #000;
    text-align: center;

    &:after {
      position: absolute;
      left: 0; right: 0; bottom: 0;
      height: 4px;
      background: red;
      opacity: 0.4;
    }
    &:hover:after {
      content: "";
    }
    &.__active:after {
      content: "";
      opacity: 1;
    }
    &.__active:hover:after {
      opacity: 0.4;
    }
  }
  &::after {
    content: '';
    position: absolute;
    width: calc(100% + 34px);
    height: 2px;
    background: #000;
    opacity: .1;
    left: -34px;
    bottom: 0;
  }
}

.tabs__body{
  padding-top: 32px;
}

.information-card__delivery-root{
  padding: 26px 35px;
}

.information-card__delivery-days{
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  margin-bottom: 16px;
}

.information-card__delivery-btns{
  display: flex;
  gap: 20px;

  & > span{
    font-size: 18px;
    line-height: 24px;
    text-decoration-line: underline;
    cursor: pointer;
    color: var(--gray);
  }
}

@media(max-width: 1023px){
  .collection__information{
    padding: 20px;
    border-radius: 0;
  }
  .tabs__header {
    & > * {
      margin-right: 16px;
      font-size: 16px;
      line-height: 25px;
    }
  }
  .tabs__body {
    padding-top: 16px;
  }
}

@media (max-width: 839px){
  .collection__information{
    flex-direction: column-reverse;
  }

  .collection__information-left,
  .collection__information-right{
    width: 100%;
    padding: 0;
  }
}

@media screen and (max-width: 640px) {
  .information-card__delivery-days{
    font-size: 24px;
    line-height: 32px;
  }
  .information-card__delivery-root {
    padding: 0 0 20px;
  }
  .information-card__delivery-btns {
    flex-direction: column;

    & > span{
      font-size: 16px;
      line-height: 22px;
    }
  }
}
</style>
