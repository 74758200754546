<template>
  <div>
    <div class="collection-slider-images">
      <SliderImages
        :images="images"
        object-fit="contain"
        padding-top="55%"
        :is-collection="isCollection"
        :is-product-card="isProductCard"

        @active-slide="slideChangeTransitionEnd"
        @open-popup-gallery="openPopupGallery"
      >
        <template
          v-if="active === 0 && productsDots"
          #template-for-dots
        >
          <DotsImage
            :dots="productsDots"
          />
        </template>
      </SliderImages>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IProductWithDots } from '~/pages/collection/[...slug].vue';
import SliderImages from '~/components/products/slider-images/SliderImages.vue';
import DotsImage from '~/components/collection/DotsImage.vue';

const { productsDots, images } = defineProps <{
		images:number[];
    isCollection?: boolean;
		productsDots?:IProductWithDots[];
    isProductCard?: boolean;
	}>();
const { openGallery } = useGallery();

const active: Ref<number> = ref(0);

const slideChangeTransitionEnd = (activeIndex: number) => {
  active.value = activeIndex;
};

const openPopupGallery = () => {
  openGallery({
    imagesIds: images,
    indexActiveSlide: active.value
  });
};
</script>

<style lang="scss" scoped>

.collection-slider-images {
  height: auto;
  position: relative;
}

.pin {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: red;
  box-shadow: 0 0 4px rgb(204 54 126 / 50%);
  position: absolute;
  z-index: 10;
  left: 150px;
  top: 145px;
}

.pin-title__image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 180px;
  position: relative;
  overflow: hidden;
  margin: 0 auto 12px 0;

  &::after {
    content: '';
    padding-top: 100%;
    float: left;
  }
}

</style>
