<template>
  <div class="delivery">
    <div
      v-if="deliveryDateDays"
      v-html="deliveryDateDays"
      class="delivery__title cms-block-stub"
    />
    <div class="delivery__label">
      {{ message }}
    </div>
  </div>
</template>

<script setup lang="ts">
const { deliveryDateDays = '' } = defineProps<{
	deliveryDateDays:string;
}>();

const message = computed(() => 'Чтобы узнать точный срок, позвоните нам или отправьте заказ из корзины');
</script>

<style scoped lang="scss">
.delivery {
  display: flex;
  flex-direction: column;
}

.delivery__title {

}

.delivery__label {
  margin-top: 15px;
  font-size: 15px;
  line-height: 24px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #000;
}
</style>
