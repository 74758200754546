<template>
  <div class="collection__collection-information">
    <div class="collection__collection-information-top">
      <div class="collection__collection-information-title">
        Пример комплектации
      </div>
      <div

        class="collection__collection-information-prices"
      >
        <div
          v-if="product.oldPrice"
          class="collection__collection-information-price-old"
        >
          {{ $priceFormat({amount:product.oldPrice}) }} ₽
        </div>

        <div
          class="collection__collection-information-price-current"
        >
          {{ $priceFormat({amount:product.price}) }} ₽
        </div>
      </div>
    </div>
    <div class="collection__collection-information-body">
      <div class="collection__collection-information-composition">
        <div
          v-for="(product, index) in productsHasPrice"
          :key="`product-has-price-${ index }`"
          class="collection__collection-information-composition-item"
        >
          <div
            class="collection__collection-information-composition-item-name"
            @click="onScrollProduct(Number(product.id))"
          >
            {{ product.name }}
          </div>
          <div class="collection__collection-information-composition-item-line" />
          <div class="collection__collection-information-composition-item-price">
            {{ $priceFormat({amount:product.price}) }} ₽
          </div>
        </div>
      </div>
    </div>
    <div class="collection__collection-information-bottom">
      <button
        class="button button-succes"
        @click="onScrollProducts"
      >
        Посмотреть другие предметы
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IProduct } from '#sitis/internal/controllers/products/models/Product';
import { useNuxtApp } from '#app';

const { productsHasPrice, product } = defineProps<{
	productsHasPrice:IProduct[];
	product:IProduct;
}>();
const emit = defineEmits<{
	onScrollProduct:[id:number],
	onScrollProducts:[]
}>();
const { $priceFormat } = useNuxtApp();
const onScrollProduct = (productId:number) => {
  emit('onScrollProduct', productId);
};
const onScrollProducts = () => {
  emit('onScrollProducts');
};

</script>

<style lang="scss">
.collection__collection-information {
  background: #fff;
  box-shadow: 0 4px 12px rgb(0 0 0 / 25%);
  border-radius: 6px;
  padding: 24px 25px 32px 37px;
  margin-bottom: 19px;
}

.collection__collection-information-top {
  display: flex;
  margin-bottom: 33px;
}

.collection__collection-information-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #000;
  margin-right: 20px;
}

.collection__collection-information-prices {
  display: flex;
  margin-left: auto;
  white-space: nowrap;
  padding-top: 5px;
}

.collection__collection-information-price-old {
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  text-align: right;
  text-decoration-line: line-through;
  color: rgb(0 0 0 / 60%);
  margin-right: 6px;
}

.collection__collection-information-price-current {
  font-weight: 700;
  font-size: 48px;
  line-height: 62px;
  text-align: right;
  color: #000;
}

.collection__collection-information-body {
  margin-bottom: 42px;
}

.collection__collection-information-composition-item {
  display: flex;
  margin-bottom: 11px;
  cursor: pointer;
}

.collection__collection-information-composition-item-name {
  font-size: 18px;
  line-height: 23px;
  text-decoration-line: underline;
  color: #000;
  transition: all .3s;

  &:hover {
    text-shadow: 0 0 1px;
  }
}

.collection__collection-information-composition-item-line {
  opacity: .2;
  border-bottom: 1px dotted #000;
  flex: auto;
  margin-bottom: 3px;
  margin-left: 5px;
  margin-right: 5px;
}

.collection__collection-information-composition-item-price {
  font-size: 18px;
  line-height: 23px;
  text-align: right;
  color: var(--secondary);
  white-space: nowrap;
  transition: all .3s;
}

@media(max-width:  1199px){
  .collection__collection-information-prices{
    flex-direction: column;
  }

  .collection__collection-information-price-current{
    font-size: 40px;
    line-height: 48px;
  }
}

@media(max-width: 1023px){
  .collection__collection-information{
    padding: 25px 20px;
  }

  .collection__collection-information-top{
    flex-direction: column;
  }

  .collection__collection-information-composition-item-name{
    font-size: 16px;
    line-height: 22px;
  }
}

@media (max-width: 839px) {
}

@media (max-width: 659px) {
  .collection__collection-information{
    padding: 10px;
  }

  .collection__collection-information-title{
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    margin-right: 0;
    margin-top: 5px;
  }

  .collection__collection-information-bottom{
    & > .button{
      text-align: center;
      width: 100%;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .collection__collection-information-price-current{
    font-size: 32px;
    line-height: 40px;
  }

  .collection__collection-information-composition-item-name{
    font-size: 12px;
    line-height: 18px;
  }

  .collection__collection-information-composition-item-price{
    font-size: 14px;
    line-height: 20px;
  }

  .collection__collection-information-prices{
    flex-direction: column-reverse;
  }

}
</style>
